<template>
  <div>
    <div class="mx-auto flex min-h-screen max-w-[2560px] flex-col">
      <TheNavigation :data="globalData?.data.attributes.navigation" />
      <TheBanner v-if="banner" class="mt-2" :content="banner" />
      <main>
        <slot />
      </main>
      <TheFooter :data="globalData?.data.attributes.footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "~/store/global";

const { banner, globalData } = useGlobalStore();
</script>

<style scoped></style>
